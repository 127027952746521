/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'components/Core/Grid';
import RichText from 'components/RichText';
import Marquee from 'components/Core/Marquee';
import useBreakpointView from 'utils/useBreakpointView';
import { SectionLayout } from 'components/Sections/SectionLayout';
import WebsiteImage from 'components/WebsiteImage';

import { Container, Companies } from './styles';

const ContentfulTrustbarV1 = ({ companies, heading, subhead, trustbarBGColor }) => {
  const isMobile = useBreakpointView(['xs']);
  const showMarquee = companies?.length > (isMobile ? 4 : 6);
  const CompaniesContainer = (isMarquee = false) => (
    <Companies isMarquee={isMarquee}>
      {companies?.map((logo, index) => (
        <div className="logo-container" key={index}>
          <WebsiteImage {...logo} />
        </div>
      ))}
    </Companies>
  );
  return (
    <SectionLayout backgroundColor={trustbarBGColor}>
      <Container>
        <Row>
          <h2>{heading}</h2>
          {subhead && (
            <div className="trustbar-copy w-full">
              <RichText content={subhead} />
            </div>
          )}
        </Row>
        {showMarquee ? (
          <Marquee speed={30}>{CompaniesContainer(true)}</Marquee>
        ) : (
          CompaniesContainer()
        )}
      </Container>
    </SectionLayout>
  );
};

export default ContentfulTrustbarV1;

ContentfulTrustbarV1.propTypes = {
  heading: PropTypes.string,
  trustbarBGColor: PropTypes.string,
  companies: PropTypes.objectOf(PropTypes.object),
};

ContentfulTrustbarV1.defaultProps = {
  heading: 'Trusted by more than 100,000+ contractors.',
  trustbarBGColor: 'White',
  companies: null,
};
