import styled from 'styled-components';

const Container = styled.div`
  padding: 0 8px;

  h2 {
    margin: 0 auto;
    text-align: center;
  }

  .trustbar-copy p {
    text-align: center;
    font-size: var(--font-size-p);
    margin: 10px 0 0;
  }
`;

const Companies = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isMarquee }) => (isMarquee ? 'space-between' : 'center')};
  margin-top: 32px;
  gap: ${({ isMarquee }) => (isMarquee ? '30px' : '48px 50px')};
  padding: 0;

  @media (max-width: 767px) {
    margin-top: 15px;
  }

  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 48px 20px;
  }

  .logo-container {
    width: ${({ isMarquee }) => (isMarquee ? '150px' : '135px')};
    @media (max-width: 1199px) {
      width: 125px;
    }
  }
`;

export { Container, Companies };
