import React from 'react';
import useTrustBarCallToAction from 'hooks/useTrustBarCallToAction';
import Text from 'components/Core/Text';
import WistiaVideo from 'components/Core/WistiaVideo';
import { STIcon } from 'components/Brand/Icons/STIcon';
import CallToAction from 'components/Contentful/ContentfulCallToAction';

import { TrustBarCardContainer } from './styles';

const TrustBarCard = ({ cardInfo }) => {
  const { description, stats, statCaption, wistiaVideo } = cardInfo;

  const defaultCallToAction = useTrustBarCallToAction();

  return (
    <TrustBarCardContainer className="trust-bar--review-wrapper">
      <div className="trust-bar--review-content-container">
        <div className="trust-bar--review-quote-container">
          <Text type="p">“{description?.description}”</Text>
        </div>
        <div className="trust-bar--review-revenue-container">
          <div className="trust-bar--review-stats-container">
            <div className="trust-bar--review-stats-percent">
              <p>{stats}</p>
              {(statCaption?.includes('Increase') || statCaption?.includes('Growth')) && (
                <STIcon type="icon-increase" />
              )}
            </div>
            <p className="trust-bar--review-stats-description">{statCaption}</p>
          </div>
          <div className="trust-bar--review-video-container">
            {wistiaVideo && (
              <WistiaVideo
                className="section-company-hero--video-button"
                {...wistiaVideo}
                buttonPopover
              />
            )}
          </div>
        </div>
      </div>
      <div className="trust-bar--review-footer-container">
        <CallToAction
          {...defaultCallToAction}
          textLabel={
            <>
              <Text type="p" textColor="black" marginMobile="0px">
                Get a ServiceTitan demo
                <STIcon type="icon-arrow-right1" style={{ margin: '0 0 -1px 6px' }} />
              </Text>
            </>
          }
        />
      </div>
    </TrustBarCardContainer>
  );
};

export default TrustBarCard;
