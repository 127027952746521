/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const TrustBarCardContainer = styled.div`
  box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);

  .trust-bar--review-content-container {
    padding: 40px 40px 25px;

    .trust-bar--review-quote-container {
      height: 120px;
      margin-bottom: 25px;
      overflow: hidden;

      p {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-20);
        line-height: var(--line-height-150);
        color: var(--darkest);
        margin-bottom: 0;
      }
    }

    .trust-bar--review-author-container {
      p {
        font-weight: var(--font-weight-500);
        font-size: var(--font-size-16);
        line-height: var(--line-height-150);
        color: var(--darkest);
        margin-bottom: 0;
      }
    }

    .trust-bar--review-revenue-container {
      margin-top: 7px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .trust-bar--review-stats-container {
        .trust-bar--review-stats-percent {
          display: flex;

          p {
            font-size: var(--font-size-35);
            font-weight: var(--font-weight-900);
            line-height: var(--line-height-110);
            letter-spacing: var(--letter-spacing--0-04);
            color: var(--titan-blue-3);
            padding: 0;
            margin: 0;
          }

          & > svg {
            vertical-align: top;
            margin-top: 5px;
            margin-left: 10px;
          }
        }

        .trust-bar--review-stats-description {
          color: var(--grey-8);
          margin: 0;
        }
      }

      .trust-bar--review-video-container {
        margin-right: -15px;
        width: 100px;
        height: 100px;
      }
    }
  }

  .trust-bar--review-footer-container {
    border-top: 1px solid var(--grey-3);
    padding: 15px;
    text-align: center;

    a {
      font-size: var(--font-size-16);
      color: var(--dark);
    }
  }

  @media (max-width: 767px) {
    .trust-bar--review-content-container {
      padding: 30px 30px 18px;

      .trust-bar--review-quote-container {
        height: 100px;

        p {
          font-size: var(--font-size-16);
        }
      }

      .trust-bar--review-revenue-container {
        .trust-bar--review-stats-container {
          .trust-bar--review-stats-percent {
            p {
              font-size: var(--font-size-28);
            }

            & > svg {
              margin-top: 0;
            }
          }

          .trust-bar--review-stats-description {
            font-size: var(--font-size-13);
          }
        }

        .trust-bar--review-video-container {
          transform: scale(0.8);
          margin-right: -5px;
          width: 80px;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .trust-bar--review-content-container {
      padding: 20px 20px 8px;
    }
  }
`;
