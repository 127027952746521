import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useBreakpointView from 'utils/useBreakpointView';
import Text from 'components/Core/Text';
import { STIcon } from 'components/Brand/Icons/STIcon';
import TrustBarCard from './Card';
import { ContentfulTrustbarV2Container } from './styles';

const ContentfulTrustbarV2 = ({ className, heading, testimonials }) => {
  const [selCompanyIndex, setSelCompanyIndex] = useState(0);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const isMobile = useBreakpointView(['xs', 'sm']);

  const slider1Settings = {
    dots: false,
    arrows: true,
    infinite: false,
    draggable: false,
    touchMove: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    accessibility: true,
    prevArrow: (
      <div className="slider-prev-arrow">
        <STIcon type="icon-slider-arrow-right" />
      </div>
    ),
    nextArrow: (
      <div className="slider-next-arrow">
        <STIcon type="icon-slider-arrow-right" />
      </div>
    ),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          infinite: true,
          draggable: true,
          touchMove: true,
        },
      },
    ],
  };

  const slider2Settings = {
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: '60px',
    variableWidth: true,
  };

  useEffect(() => {
    if (nav1) {
      nav1.slickGoTo(selCompanyIndex, !isMobile);
    }
  }, [selCompanyIndex]);

  return (
    <ContentfulTrustbarV2Container className={`trust-bar--section-container ${className || ''}`}>
      <div className="trust-bar--wrapper">
        <div className="trust-bar--title-container">
          <Text type="h2" className="trust-bar--title">
            {heading}
          </Text>
        </div>
        {testimonials && testimonials.length > 0 && (
          <div className="trust-bar--content-container">
            <div className="trust-bar--review-container">
              <Slider asNavFor={nav2} ref={(slider) => setNav1(slider)} {...slider1Settings}>
                {testimonials.map((item, index) => (
                  <div className="trust-bar--review-item" key={index}>
                    <TrustBarCard cardInfo={item} />
                  </div>
                ))}
              </Slider>
            </div>
            <div className="trust-bar--company-container">
              <div className="trust-bar--company-desktop-container min-md">
                <div className="trust-bar--company--logo-container">
                  {testimonials.map((item, index) => (
                    <div
                      className={`trust-bar--company-logo-item ${
                        selCompanyIndex === index ? 'item-selected' : ''
                      }`}
                      style={{
                        backgroundImage: `url(${item?.person?.company?.logo?.fullColor?.image?.file.url})`,
                      }}
                      onClick={() => setSelCompanyIndex(index)}
                      key={index}
                    />
                  ))}
                </div>
              </div>
              <div className="trust-bar--company-mobile-container max-md">
                <div className="trust-bar--company--logo-container">
                  <Slider asNavFor={nav1} ref={(slider) => setNav2(slider)} {...slider2Settings}>
                    {testimonials.map((item, index) => (
                      <div className="trust-bar--company-logo-item" key={index}>
                        <div
                          className="trust-bar--company-logo-item-wrapper"
                          style={{
                            backgroundImage: `url(${item?.person?.company?.logo?.fullColor?.image?.file.url})`,
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ContentfulTrustbarV2Container>
  );
};

export default ContentfulTrustbarV2;

ContentfulTrustbarV2.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
};

ContentfulTrustbarV2.defaultProps = {
  heading: 'Trusted by more than 100,000+ contractors.',
};
