/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ContentfulTrustbarV2Container = styled.div`
  .trust-bar--wrapper {
    width: 100%;
    max-width: 1296px;
    margin: 0px auto;
    padding: 75px 28px;
    position: relative;

    .trust-bar--title-container {
      .trust-bar--title {
        font-weight: var(--font-weight-900);
        text-align: center;
        color: var(--darkest);
      }
    }

    .trust-bar--content-container {
      display: flex;
      margin-top: 60px;
      align-items: center;

      .trust-bar--review-container {
        flex: 50;
        min-width: 0;

        .trust-bar--review-item {
          padding: 5px 30px 50px;
        }

        .slick-slider {
          .slick-dots {
            bottom: -5px;

            li {
              width: 10px;
              height: 14px;
              margin: 0 3px;

              button {
                width: 10px;
                height: 10px;
                padding: 0px;

                &:before {
                  font-size: var(--font-size-13);
                  line-height: 10px;
                  width: 10px;
                  height: 10px;
                  opacity: 1;
                  color: var(--grey-4);
                }
              }

              &.slick-active {
                button {
                  &:before {
                    color: var(--titan-blue-2);
                  }
                }
              }
            }
          }

          .slick-arrow {
            display: none !important;

            @media (max-width: 767px) {
              position: absolute;
              top: auto;
              bottom: -28px;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              border: 1px solid #f6f8fa;
              box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
              display: flex !important;
              align-items: center;
              justify-content: center;
              z-index: 101;

              &:before {
                content: none;
              }

              svg {
                width: 15px;
                height: 15px;
              }

              &.slick-prev {
                left: 20px;
                svg {
                  transform: scale(-1);
                }
              }

              &.slick-next {
                right: 20px;
              }
            }
          }
        }
      }

      .trust-bar--company-container {
        flex: 65;
        min-width: 0;
        padding-left: 10px;
        text-align: right;

        .trust-bar--company--header-container {
          display: flex;
          justify-content: flex-end;

          .trust-bar--company--see-all {
            font-weight: var(--font-weight-500);
            font-size: var(--font-size-16);
            line-height: var(--line-height-150);
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 10px 28px 0;
            color: var(--titan-blue-3);

            .trust-bar--company--link-arrow {
              width: 24px;
              margin-left: 5px;
              display: flex;
              align-items: center;

              &.arrow-hovered {
                display: none;
              }
            }

            &:hover {
              color: var(--titan-blue-4);

              .trust-bar--company--link-arrow {
                display: none;

                &.arrow-hovered {
                  display: flex;
                }
              }
            }
          }
        }

        .trust-bar--company-desktop-container {
          margin-bottom: 30px;

          .trust-bar--company--logo-container {
            display: flex;
            flex-wrap: wrap;

            .trust-bar--company-logo-item {
              width: calc(33% - 20px);
              padding: 25px;
              display: flex;
              align-items: center;
              margin: 10px;
              border-radius: 5px;
              border: 1.5px solid transparent;
              cursor: pointer;
              padding-bottom: calc(16.5% - 5px);
              background-size: calc(100% - 50px);
              background-repeat: no-repeat;
              background-position: center;
              transition: box-shadow 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);
              overflow: hidden;
              position: relative;

              .trust-bar--company-logo {
                width: 100%;
              }

              &:hover {
                border: 1.5px solid var(--titan-blue-2);

                &:before {
                  content: '';
                  position: absolute;
                  background-color: var(--titan-blue-3);
                  opacity: 0.05;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                }
              }

              &.item-selected {
                box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.1);
                background-color: var(--white);
                border: 1.5px solid transparent;

                &:hover {
                  &:before {
                    content: none;
                  }
                }
              }

              @media (max-width: 987px) {
                width: calc(50% - 20px);
                padding-bottom: calc(25% - 5px);
              }
            }
          }
        }

        .trust-bar--company-mobile-container {
          .trust-bar--company--logo-container {
            display: block;

            .trust-bar--company-logo-item {
              height: 75px;

              .trust-bar--company-logo-item-wrapper {
                width: 100%;
                height: 100%;
                min-width: 210px;
                padding: 0 30px;
                display: flex;
                align-items: center;

                .trust-bar--company-logo {
                  width: 100%;
                  height: auto;
                  max-height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 987px) {
    .trust-bar--wrapper {
      .trust-bar--content-container {
        .trust-bar--review-container {
          flex: 70;
        }

        .trust-bar--company-container {
          flex: 50;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .trust-bar--wrapper {
      padding-left: 20px;
      padding-right: 20px;
      padding: 50px 20px 5px;
      overflow: hidden;

      .trust-bar--title-container {
        .trust-bar--title {
          font-size: var(--font-size-28);
        }
      }

      .trust-bar--content-container {
        flex-direction: column-reverse;
        margin-top: 30px;

        .trust-bar--review-container {
          flex: auto;
          margin: 20px -20px;
          padding-bottom: 45px;
          overflow: hidden;
          width: 100%;

          .trust-bar--review-item {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        .trust-bar--company-container {
          flex: auto;
          height: 75px;

          .trust-bar--company-mobile-container {
            .trust-bar--company--logo-container {
              .trust-bar--company-logo-item {
                .trust-bar--company-logo-item-wrapper {
                  min-width: 190px;
                  padding: 0 20px;
                  background-size: calc(100% - 50px);
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }

              .slick-slider {
                .slick-list {
                  overflow: visible;
                }
              }
            }
          }
        }
      }
    }
  }
`;
