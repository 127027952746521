import { graphql, useStaticQuery } from 'gatsby';

const useTrustBarCallToAction = () => {
  const { contentfulCallToAction } = useStaticQuery(graphql`
    query {
      contentfulCallToAction(contentful_id: { eq: "2oUcRifE6qMWdYlPVDiOhF" }) {
        ...CallToAction
      }
    }
  `);

  return contentfulCallToAction;
};

export default useTrustBarCallToAction;
