import React from 'react';
import ContentfulTrustbarV1 from './ContentfulTrustbarV1';
import ContentfulTrustbarV2 from './ContentfulTrustbarV2';

const ContentfulTrustbar = (props) => {
  return props?.style === 'v2' ? (
    <ContentfulTrustbarV2 {...props} />
  ) : (
    <ContentfulTrustbarV1 {...props} />
  );
};

export default ContentfulTrustbar;
